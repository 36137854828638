import accPlusIcon from 'assets/acc-plus-icon.svg';
import enterpriseIcon from 'assets/enterprise-icon.svg';
import basicIcon from 'assets/basic-icon.svg';

const TIERS = [
  {
    name: 'Starter',
    subTitle: 'To get started',
    description: 'Get started with finding NDIS participants with population ' +
      'coverage targeting of approximately 1 to 5 million.',
    price: {
      annually: 59,
      quarterly: 83,
    },
    billedPrice: {
      annually: 699,
      quarterly: 249,
    },
    billedType: {
      annually: 'Annually',
      quarterly: 'Quarterly',
    },
    icon: basicIcon,
    alternate: true,
    benefits: [
      'Up to 3 service requests per month',
      'Advertise up to 3 services',
      'Accreditation badge',
      'Connect via direct connect button, social & web links only',
    ],
    btPlanId: {
      annually: '20230914-ANNUAL-BASIC-ACCREDITATION',    // Should exactly match with Braintree plan
      quarterly: '20230914-QUARTERLY-BASIC-ACCREDITATION',    // Should exactly match with Braintree plan
    },
    subscriptionTierName: 'starter',    // Should exactly match with backend db
  },
  {
    name: 'Accreditation Plus',
    subTitle: 'Increase exposure',
    description: 'Perfect for growing NDIS businesses with population coverage ' +
      'targeting of around 5 to 10 million & advanced endorsement options.',
    price: {
      annually: 109,
      quarterly: 149,
    },
    billedPrice: {
      annually: 1299,
      quarterly: 447,
    },
    billedType: {
      annually: 'Annually',
      quarterly: 'Quarterly',
    },
    icon: accPlusIcon,
    alternate: false,
    benefits: [
      'Up to 5 service requests per month',
      'Advertise up to 5 services',
      'Larger prioritised directory listing with NDIS & Accreditation badging',
      'Connect via phone, direct connect button social & SEO friendly web link',
      'Larger geolocation targeting',
      'Showcase your photos with Photo Gallery',
    ],
    btPlanId: {
      annually: '20230914-ANNUAL-PLUS-ACCREDITATION',   // Should exactly match with Braintree plan
      quarterly: '20230914-QUARTERLY-PLUS-ACCREDITATION',   // Should exactly match with Braintree plan
    },
    subscriptionTierName: 'accreditation_plus',   // Should exactly match with backend db
  },
  {
    name: 'Enterprise',
    subTitle: 'For big companies',
    description: 'Nationwide coverage and unlimited access to all platform features.',
    price: {
      annually: 333,
      quarterly: 499,
    },
    billedPrice: {
      annually: 3996,
      quarterly: 1497,
    },
    billedType: {
      annually: 'Annually',
      quarterly: 'Quarterly',
    },
    icon: enterpriseIcon,
    alternate: true,
    benefits: [
      'Unlimited service requests',
      'Advertise all your services',
      'Larger prioritised directory listing with NDIS & Accreditation badging ',
      'Connect via phone, direct connect button social & SEO friendly web link',
      'Nationwide geolocation targeting',
      'Showcase your photos with Photo Gallery',
    ],
    btPlanId: {
      annually: '20230914-ANNUAL-ENTERPRISE-ACCREDITATION',   // Should exactly match with Braintree plan
      quarterly: '20230914-QUARTERLY-ENTERPRISE-ACCREDITATION',   // Should exactly match with Braintree plan
    },
    subscriptionTierName: 'enterprise',   // Should exactly match with backend db
  },
];

export const TIER_ICONS = {
  'Starter': basicIcon,
  'Accreditation Plus': accPlusIcon,
  'Enterprise': enterpriseIcon,
};

export default TIERS;
