import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import snakecaseKeys from 'snakecase-keys';

import SpinnerModal from 'components/SpinnerModal';
import { AuthAPI } from 'api';
import style from 'style';
import { AuthStore } from 'store';
import {
  UTM_CAMPAIGN_KEY,
  UTM_CONTENT_KEY,
  UTM_ID_KEY,
  UTM_MEDIUM_KEY,
  UTM_SOURCE_KEY,
  HICAPS_BILLER_ID_KEY,
} from 'providerSite/constants';
import Subscription from 'components/Subscription';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  row-gap: 10px;
  margin-top: -60px;
  @media (max-width: ${style.sizes.small}) {
    margin-top: 0;
  }
`;


const BillingPage = ({ answers }) => {

  const dispatch = useDispatch();

  const [busy, setBusy] = useState(false);
  const [signupError, setSignupError] = useState();
  const history = useHistory();

  const removeSessionStorageData = () => {
    window.sessionStorage.removeItem(UTM_SOURCE_KEY);
    window.sessionStorage.removeItem(UTM_MEDIUM_KEY);
    window.sessionStorage.removeItem(UTM_CAMPAIGN_KEY);
    window.sessionStorage.removeItem(UTM_ID_KEY);
    window.sessionStorage.removeItem(UTM_CONTENT_KEY);
    window.sessionStorage.removeItem(HICAPS_BILLER_ID_KEY);
  };

  const onComplete = async args => {
    setBusy(true);
    setSignupError(null);
    const data = {
      email: answers.user.email,
      username: answers.user.email,
      organisation: answers.provider.businessName,
      firstName: answers.user.firstName,
      lastName: answers.user.lastName,
      password: answers.user.password,
      contactPhone: answers.provider.phone,
      entityName: answers.provider.businessName,
      jobRole: answers.provider.jobRole,
      emailOptIn: true,
      hasAccreditationAccess: answers.goal === 'growth',
      hasConnectAccess: answers.goal === 'connect',
    };

    const sessionStorageData = {
      [UTM_SOURCE_KEY]: window.sessionStorage.getItem(UTM_SOURCE_KEY),
      [UTM_MEDIUM_KEY]: window.sessionStorage.getItem(UTM_MEDIUM_KEY),
      [UTM_CAMPAIGN_KEY]: window.sessionStorage.getItem(UTM_CAMPAIGN_KEY),
      [UTM_ID_KEY]: window.sessionStorage.getItem(UTM_ID_KEY),
      [UTM_CONTENT_KEY]: window.sessionStorage.getItem(UTM_CONTENT_KEY),
      [HICAPS_BILLER_ID_KEY]: window.sessionStorage.getItem(HICAPS_BILLER_ID_KEY),
    };

    try {
      const { payload: signupPayload } = await AuthAPI.providerSignup({
        ...snakecaseKeys(data, {deep: true}),
        ...sessionStorageData,
        ...args
      });
      const { payload } = await AuthAPI.providerLogin(data.username, data.password);
      const { profile, id, token_ttl } = payload;
      dispatch(AuthStore.login(profile, id, token_ttl));
      let url;
      if (data.hasAccreditationAccess)
        url = `/provider/${signupPayload.uuid}/admin/listings`;
      else
        url = `/connect/${signupPayload.uuid}/dashboard`;
      removeSessionStorageData();
      history.push(url, {isNewUser: true});

    } catch(err) {
      setBusy(false);
      if (err.body) {
        const { message } = err.body;
        setSignupError(message);
      } else {
        setSignupError('An error occurred updating your profile.');
      }
    }
  };

  useEffect(() => {
    if (answers.goal !== 'connect') return;
    onComplete({billing_plan_id: null, subscription_tier_name: 'free'});
  }, [answers.goal]);

  if (answers.goal === 'connect') {
    return (
      <Container>
        <SpinnerModal isLoading={busy} />
      </Container>
    );
  }

  return (
    <Container>
      <Subscription
        onComplete={onComplete}
        signupError={signupError}
        setSignupError={setSignupError}
        busy={busy}
        trackingEmail={answers?.user?.email}
      />
    </Container>
  );
};

BillingPage.propTypes = {
  answers: PropTypes.object,
  setAnswers: PropTypes.func,
};


export default BillingPage;
