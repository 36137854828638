import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Button } from 'antd';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import Lottie from 'lottie-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight } from '@fortawesome/pro-solid-svg-icons';
import { faLocationDot, faPeopleGroup, faFiles } from '@fortawesome/pro-duotone-svg-icons';
import { useQueryClient } from '@tanstack/react-query';

import style from 'style';
import { renderTime } from './utils';
import Question from './components/Question';
import AnimatedButton from './components/AnimatedButton';
import timeOutanimationAsset from 'assets/sandwatch.json';
import errorAnimationAsset from 'assets/error.json';
import queueAnimationAsset from 'assets/queue.json';
import { ServiceRequestAPI, useGetApi, useMutateApi } from 'providerSite/api';
import { SERVICE_REQUEST_EVENT_ACCEPT } from 'components/constants';
import Spinner from 'components/Spinner';
import { ANSWER_YES } from '../../modules/brokerageFlow/constants';
import { usePusher } from 'lib/pusher';
import {
  PUSHER_CHANNEL_PRIVATE_SR_QUEUE,
  PUSHER_CHANNEL_SR_QUEUE, PUSHER_EVENT_CLIENT_UPDATE_QUEUE,
  PUSHER_EVENT_UPDATE_QUEUE
} from '../../lib/pusherConstants';


const Container = styled.div`
  background: white;
  backdrop-filter: saturate(180%) blur(5px);
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid ${style.colors.lightBorder};
  width: 100%;
  max-width: 750px;
  border-radius: 26px;
  padding: 25px 35px;
  cursor: default;
  filter: drop-shadow(0px 1px 100px rgba(242, 104, 73, 0.2));
  min-height: 405px;
  white-space: pre-wrap;
  
  @media (max-width: ${style.sizes.large}) {
    width: calc(100% - 40px);
  }

  .lottie-container {
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.5));
  }

  .not-available-title {
    text-align: center;
    font-size: 22px;
    color: ${style.colors.palette.dustyOrange};
    font-weight: 700;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.15));
  }
  
  .reason {
    font-size: 14px;
    color: ${style.colors.textPrimary};
    text-align: center;
  }

  .timed-out-footer {
    font-size: 11px;
    color: #b3b3b3;
    margin-top: 3px;
  }
  
  .queue {
    font-size: 16px;
    color: black;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.15));
  }
  
  .queue-number {
    font-size: 28px;
    vertical-align: -3px;
    color: ${style.colors.palette.dustyOrange};
    font-weight: 700;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${style.sizes.large}) {
    flex-direction: column;
    row-gap: 20px;
    align-items: center;
    margin-bottom: 20px;
    text-align: center;
  }

  .title {
    color: black;
    font-size: 20px;
    font-weight: 700;
  }

  .text {
    font-size: 13px;
    font-weight: 400;
    white-space: pre-wrap;
    color: ${style.colors.textPrimary};
    max-width: 500px;
  }

  .counter-container {
    filter: drop-shadow(0px 1px 1px rgba(32, 32, 32, 0.2));
  }
`;

const Questionnaire = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  font-size: 18px;
  font-weight: 700;
  color: ${style.colors.secondary};
  margin-top: 10px;
  
  .icon {
    margin-right: 8px;
    font-size: 18px;
    width: 20px;
    vertical-align: -3px;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  @media (max-width: ${style.sizes.large}) {
    flex-direction: column-reverse;
    row-gap: 20px;
    align-items: center;
  }
  
  .button-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .button-content-main {
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
  }
  
  .button-content-bottom {
    font-size: 11px;
    line-height: 12px;
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.4));
  }
`;

const ReserveModal = (
  {
    data,
    serviceRequestUuid,
    providerUuid,
    onCancel,
    onReQueue,
    onAccept,
    duration = 60
  }) => {

  const [ remaining, setRemaining ] = useState(duration);
  const [ questionData, setQuestionData ] = useState({});

  const [ isLoading, setIsLoading ] = useState(true);
  const [ isReservationAvailable, setIsReservationAvailable ] = useState(false);
  const [ queuePosition, setQueuePosition ] = useState();
  const [ queueUuid, setQueueUuid ] = useState();
  const [ message, setMessage ] = useState('');
  const queryClient = useQueryClient();

  const createMutation = useMutateApi(ServiceRequestAPI.POST_SR_QUEUE, 'POST', {
    onSuccess: data => {
      // eslint-disable-next-line react/prop-types
      const response = data?.payload;
      setQueueUuid(response.uuid);
      setIsReservationAvailable(response?.reservationAvailable);
      setQueuePosition(response?.queuePosition);
      setMessage(response?.message ? response.message : '');
      setIsLoading(false);
    },
  });

  const deleteMutation = useMutateApi(ServiceRequestAPI.DELETE_SR_QUEUE, 'DELETE', {
    params: {
      uuid: queueUuid,
    },
  });

  const { data: updatedQueueData } = useGetApi(ServiceRequestAPI.GET_SR_QUEUE, {
    params: {
      uuid: queueUuid,
    },
    enabled: !!queuePosition && !!queueUuid && !!isReservationAvailable,
    staleTime: 0,   // No caching
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: true,
    refetchInterval: 1000 * 30,   // 30 seconds
  });

  const invalidateQueue = () => {
    queryClient.invalidateQueries();
  };

  usePusher(PUSHER_CHANNEL_SR_QUEUE + serviceRequestUuid, PUSHER_EVENT_UPDATE_QUEUE, invalidateQueue);
  const { push } = usePusher(PUSHER_CHANNEL_PRIVATE_SR_QUEUE + serviceRequestUuid, PUSHER_EVENT_CLIENT_UPDATE_QUEUE, invalidateQueue);

  const handleQuestionClick = key => () => {
    setQuestionData(prevState => ({...prevState, [key]: true}));
  };

  const handleUpdate = remainingTime => {
    setRemaining(remainingTime);
  };

  const handleCancel = () => {
    deleteMutation.mutate({});
    push();
    onCancel();
  };

  const isReqMet = questionData.location && questionData.age && questionData.ndis;

  const renderBottomText = () => {
    if (remaining > 30)
      return <>Wait... {remaining - 30} seconds</>;
    if (!isReqMet)
      return <>Please confirm all the requirements to accept</>;
    return <>Ready to accept</>;
  };

  const getConsultationType = () => {
    const _data = data?.data ? data.data : {};

    if (_data.telehealth === ANSWER_YES && _data.onlineService === ANSWER_YES)
      return 'in-person or telehealth or online';

    if (_data.telehealth === ANSWER_YES)
      return 'in-person or telehealth';

    if (_data.onlineService === ANSWER_YES)
      return 'in-person or online';

    return 'in-person';
  };

  useEffect(() => {
    setIsLoading(true);
    setQueueUuid(null);
    createMutation.mutate({serviceRequestUuid, providerUuid});
  }, []);

  useEffect(() => {
    if (!updatedQueueData) return;
    setIsReservationAvailable(updatedQueueData.reservationAvailable);
    setQueuePosition(updatedQueueData.queuePosition);
    setMessage(updatedQueueData.message ? updatedQueueData.message : '');
  }, [updatedQueueData]);

  if (isLoading) {
    return (
      <CenterContainer onCancel={handleCancel}>
        <Spinner />
        <div>Loading reservation... please wait</div>
      </CenterContainer>
    );
  }

  if (!isReservationAvailable) {
    return (
      <CenterContainer onCancel={handleCancel}>
        <div className="not-available-title">Sorry, reservation is not available at this moment</div>
        <div className="lottie-container">
          <Lottie
            loop
            animationData={errorAnimationAsset}
            style={{height: '220px', marginTop: '10px', marginBottom: '10px'}}
          />
        </div>
        <div className="reason"><b>Reason:</b> {message}</div>
      </CenterContainer>
    );
  }

  if (queuePosition) {
    return (
      <CenterContainer onCancel={handleCancel}>
        <div className="not-available-title">Please wait, you are in the queue</div>
        <div className="lottie-container">
          <Lottie
            loop
            animationData={queueAnimationAsset}
            style={{height: '220px', marginTop: '10px', marginBottom: '10px'}}
          />
        </div>
        <div className="queue">Queue Position: <span className="queue-number">{queuePosition}</span></div>
      </CenterContainer>
    );
  }

  if (remaining <= 0) {
    return (
      <CenterContainer>
        <div className="not-available-title">
          Your reservation has timed out!
        </div>
        <div className="lottie-container">
          <Lottie
            loop
            animationData={timeOutanimationAsset}
            style={{height: '300px', marginTop: '-40px', marginBottom: '-50px'}}
          />
        </div>
        <Button
          style={{width: '200px', marginTop: '25px', height: '35px', borderRadius: '12px'}}
          type="primary"
          onClick={() => {
            deleteMutation.mutate({});
            onReQueue();
          }}
        >
          <FontAwesomeIcon style={{marginRight: '5px'}} icon={faRotateRight} />
          Re-queue
        </Button>
        <div className="timed-out-footer">
          *Subject to availability
        </div>
      </CenterContainer>
    );
  }

  return (
    <Container onClick={e => e.stopPropagation()}>
      <Header>
        <div>
          <div className="title">
            Before you accept this request
          </div>
          <div className="text">
            This request is on <u><b>hold for you</b></u> for the <u><b>next 60 seconds</b></u> to
            give you time to review all the requirements of the request to make
            an informed decision on accepting it and using your credits.
            <br/>Rest assured, no one will be able to take this request away from
            you within the next 60 seconds.
          </div>
        </div>
        <div className="counter-container">
          <CountdownCircleTimer
            isPlaying
            duration={duration}
            size={120}
            strokeWidth={8}
            colors={['#4caf50', '#ff9800', '#f44336']}
            colorsTime={[30, 15, 0]}
            trailColor="#f0f0f0"
            onUpdate={handleUpdate}
          >
            {({color}) => renderTime(remaining, color)}
          </CountdownCircleTimer>
        </div>
      </Header>
      <Questionnaire>
        Please select YES to confirm that:
        <Question onClick={handleQuestionClick('location')} isChecked={questionData.location}>
          <FontAwesomeIcon icon={faLocationDot} className="icon" />
          You are able to service <b>{data.location}</b> as in <b>{getConsultationType()}</b> consultation
        </Question>
        <Question onClick={handleQuestionClick('age')} isChecked={questionData.age}>
          <FontAwesomeIcon icon={faPeopleGroup} className="icon" />
          The client&apos;s age range is <b>{data.participantAgeRange}</b>, and you have staff <b>available</b>
        </Question>
        <Question onClick={handleQuestionClick('ndis')} isChecked={questionData.ndis}>
          <FontAwesomeIcon icon={faFiles} className="icon" />
          <b>Your NDIS registration is current</b> and you can bill this client who is <b>{data.data.ndisPlan}</b>
        </Question>
      </Questionnaire>
      <Footer>
        <Button
          danger
          style={{borderRadius: '14px', height: '40px', width: '200px'}}
          onClick={handleCancel}
        >
          Cancel this request
        </Button>
        <AnimatedButton
          disabled={!isReqMet || remaining > 30}
          onClick={() => {
            onAccept(SERVICE_REQUEST_EVENT_ACCEPT);
            onCancel();
          }}
        >
          <div className="button-content">
            <div className="button-content-main">
              Accept
            </div>
            <div className="button-content-bottom">
              {renderBottomText()}
            </div>
          </div>
        </AnimatedButton>
      </Footer>
    </Container>
  );
};


ReserveModal.propTypes = {
  data: PropTypes.object,
  serviceRequestUuid: PropTypes.string,
  providerUuid: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onReQueue: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired,
  duration: PropTypes.number,
};


const CenterContainer = ({ children, onCancel }) => {
  return (
    <Container
      style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}
      onClick={e => e.stopPropagation()}
    >
      {children}
      {onCancel && (
        <Button
          style={{width: '200px', marginTop: '25px', height: '35px', borderRadius: '12px'}}
          type="primary"
          danger
          onClick={onCancel}
        >
          Cancel
        </Button>
      )}
    </Container>
  );
};

CenterContainer.propTypes = {
  children: PropTypes.node,
  onCancel: PropTypes.func,
};


export default ReserveModal;

