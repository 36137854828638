import React, { useState } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Rate, Tooltip, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentLines } from '@fortawesome/pro-regular-svg-icons';
import { faHandshake } from '@fortawesome/pro-solid-svg-icons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';

import ImagePanel from './ImagePanel';
import SpecialisationPanel from './SpecialisationPanel';
import accreditedLogo from 'assets/accredited-provider-badge2.svg';
import { imageDx } from './ImagePanel';
import style from 'style';
import ndis from 'assets/ndis-icon-round.svg';
import * as track from 'lib/track';
import {
  SUBSCRIPTION_TIER_ACCREDITATION_PLUS,
  SUBSCRIPTION_TIER_ENTERPRISE,
} from 'lib/constants';
import DirectConnectModal from 'components/DirectConnectModal';


const PREMIUM_TYPE_SPONSORED = 'sponsored';
const PREMIUM_TYPE_EXCLUSIVE = 'exclusive';


const useStyles = makeStyles(theme => ({
  root: {
    userSelect: 'none',
    color: 'black',
    flexGrow: 1,
    '&:hover': {
      cursor: 'pointer',
    },
    position: 'relative',
  },
  iconContainer: {
    marginTop: '10px',
    marginRight: '10px',
  },
  checkbox: {
    marginTop: '5px',
  },
  sponsored: {
    position: 'absolute',
    top: '-25px',
    right: 0,
    color: theme.palette.common.squash,
    fontSize: '12px',
    fontWeight: '400',
    textShadow: '1px 1px #e8e8e8',
    cursor: 'default',
    border: '1px solid',
    borderColor: theme.palette.common.squash,
    padding: '0 4px',
    borderRadius: '6px',
  },
  exclusive: {
    color: theme.palette.common.dustyOrange,
    borderColor: theme.palette.common.dustyOrange,
  },
  card: {
    textDecoration: 'none',
    marginBottom: '2rem',
    borderRadius: '12px',
    border: '1px solid',
    borderColor: ({premiumType}) => premiumType === null ? theme.palette.common.lightBorder :
      (premiumType === PREMIUM_TYPE_SPONSORED ? theme.palette.common.squash : theme.palette.common.dustyOrange),
    '&:hover': {
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    boxShadow: style.shadow(20),
    overflow: 'hidden',
  },
  titleText: {
    fontSize: '18px',
    fontWeight: '500',
    maxWidth: '450px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('md')]: {
      maxWidth: '230px',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '400px',
    },
  },
  descriptionText: {
    //  these lines are all required for line clamping
    // ---->
    WebkitLineClamp: 3,
    fontSize: '14px',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    height: 'max-content',
    color: style.colors.palette.textGrey,
    // <----
  },
  mobileReviewRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  filledRow: {
    display: 'flex',
    flexGrow: '1',
  },
  col: {
    padding: '10px 12px 10px 15px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  coverImgCell: {
    display: 'flex',
    flex: `0 0 ${imageDx}px`,
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      flex: '0 1 auto',  // restore to default
    }
  },
  titleCell: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    marginBottom: '6px',
    // padding: '0.2rem 0.5rem 0 1.0rem',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      marginTop: '15px',
    }
  },
  serviceCell: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '0.2rem',
  },
  ratingCell: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '20px',
    marginTop: '2px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginTop: '0',
    }
  },
  accreditationCell: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    columnGap: '10px',
    minHeight: '30px',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      columnGap: '30px',
      marginTop: '8px',
    }
  },
  settings: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: '10px',
    marginRight: '10px',
  },
  settingsIcon: {
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  specialisationCell: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
    borderTop: `1px solid ${style.colors.lightBorder}`,
    padding: '10px',
  },
  accreditedImg: {
    margin: 2,
    width: 100,
    objectFit: 'scale-down',
    filter: ({isGrayAccImage}) => isGrayAccImage && 'grayscale(100%) brightness(95%)',
  },
  reviews: {
    fontSize: '13px',
    display: 'flex',
    columnGap: '5px',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: '500',
  },
  reviewIcon: {
    color: style.colors.palette.dustyOrange,
    fontSize: '16px',
  },
  mobileDescription: {
    padding: '12px 20px 15px 20px',
  },
  dcButtonContainer: {
    marginTop: '10px',
    minHeight: '32px',
  },
  dcButton: {
    maxWidth: '190px',
    fontSize: '12px',
    background: theme.palette.common.white,
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    '&:hover': {
      borderColor: theme.palette.primary.main,
      background: theme.palette.primary.main,
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '11px',
      maxWidth: '170px',
      padding: '0 5px',
    }
  },
}));

const bodyText = (data, classes) => (
  <Typography variant="body2" className={classes.descriptionText}>
    {data.shortDescription}
  </Typography>
);

const ProviderCard = ({ data, externalDirectoryMode }) => {

  let premiumType = null;
  if (data?.isSponsored)
    premiumType = PREMIUM_TYPE_SPONSORED;
  else if (data?.isExclusive)
    premiumType = PREMIUM_TYPE_EXCLUSIVE;

  const theme = useTheme();
  const useMobileLayout = useMediaQuery(theme.breakpoints.down('sm'));

  const isFullAccessTier = [
    SUBSCRIPTION_TIER_ACCREDITATION_PLUS,
    SUBSCRIPTION_TIER_ENTERPRISE,
  ].includes(data?.subscription);

  const hasServices = !!data.services && Array.isArray(data.services) && !!data.services.length;

  const classes = useStyles({premiumType: premiumType, isGrayAccImage: !isFullAccessTier});
  const endorsedSpecialisations = data.specialisations.filter(s => s.endorsements);
  const [directConnectOpen, setDirectConnectOpen] = useState(false);

  const handleCardClick = () => {
    track.sendEvent(track.DIRECTORY_PROVIDER_CLICK, track.EVENT_CLICK, {
      provider_uuid: data?.uuid,
    }, [data?.uuid]);
  };

  const handleDCButtonClick = event => {
    event.preventDefault();
    event.stopPropagation();
    setDirectConnectOpen(true);
  };

  const renderAccreditedLogo = () => {
    if (!data.accredited) return null;
    return (
      <Tooltip
        title="Provider is accredited by Clickability"
        overlayInnerStyle={{
          fontSize: '10px',
          fontWeight: 500,
          padding: '5px 8px 2px 8px',
          margin: 0,
          minHeight: 0,
        }}
      >
        <img
          src={accreditedLogo}
          aria-label="Provider is accredited by Clickability"
          alt="Clikability accredited provider icon"
          className={classes.accreditedImg}
        />
      </Tooltip>
    );
  };

  const renderNDISLogo = () => {
    if (!data.ndisRegistered || !isFullAccessTier) return null;
    return (
      <Tooltip
        title="Registered NDIS provider"
        overlayInnerStyle={{
          fontSize: '10px',
          fontWeight: 500,
          padding: '5px 8px 2px 8px',
          margin: 0,
          minHeight: 0,
        }}
      >
        <img
          src={ndis}
          aria-label="Registered NDIS provider"
          alt="Registered NDIS provider"
          style={{width: '27px', marginTop: '1px'}}
        />
      </Tooltip>
    );
  };

  const renderPremium = () => {
    switch (premiumType) {
    case PREMIUM_TYPE_SPONSORED:
      return <div className={classes.sponsored}>Sponsored</div>;
    case PREMIUM_TYPE_EXCLUSIVE:
      return (
        <div className={classNames(classes.sponsored, classes.exclusive)}>
          Preferred Partner
        </div>
      );
    default:
      return null;
    }
  };

  const renderDirectConnectButton = () => {
    if (!data.accredited || !hasServices || !isFullAccessTier) return null;
    return (
      <Button
        className={classes.dcButton}
        type="primary"
        onClick={handleDCButtonClick}
      >
        <FontAwesomeIcon style={{marginRight: '5px', fontSize: '16px', verticalAlign: '-3px'}} icon={faHandshake}/>
        Connect with this provider
      </Button>
    );
  };

  const desktopLayout = () => {
    return (
      <div className={classes.root} onClick={handleCardClick}>
        {renderPremium()}
        <div className={classes.card}>
          <div className={classes.row}>
            <div className={classes.coverImgCell}>
              <ImagePanel data={data} useMobileLayout={false}/>
            </div>
            {/* cell 2 contains 2 rows each with 2 columns */}
            <div className={classes.col} style={{height: '160px'}}>
              <div className={classes.row}>
                <div className={classes.titleCell}>
                  <div>
                    <div className={classes.titleText}>{data.name}</div>
                  </div>
                  <div className={classes.ratingCell}>
                    <Rate disabled allowHalf value={data.accredited ? data.rating : 0} />
                    {data.accredited && (
                      <div className={classes.reviews}>
                        <FontAwesomeIcon className={classes.reviewIcon} icon={faCommentLines}/>
                        {data.reviewCount} Reviews
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div className={classes.accreditationCell}>
                    {renderNDISLogo()}
                    {renderAccreditedLogo()}
                  </div>
                  <div className={classes.dcButtonContainer}>
                    {!externalDirectoryMode && renderDirectConnectButton()}
                  </div>
                </div>
              </div>

              <div className={classes.row}>

                <div className={classes.serviceCell}>
                </div>
              </div>
              <div className={classes.filledRow}>
                {bodyText(data, classes)}
              </div>
            </div>
          </div>
          {(isFullAccessTier && endorsedSpecialisations.length > 0) && (
            <div className={classes.specialisationCell}>
              <SpecialisationPanel specialisations={endorsedSpecialisations}/>
            </div>
          )}
        </div>
      </div>
    );
  };

  const mobileLayout = () => {
    return (
      <div className={classes.root} onClick={handleCardClick}>
        {renderPremium()}
        <div className={classes.card}>
          <div className={classes.coverImgCell}>
            <ImagePanel data={data} useMobileLayout={true}/>
          </div>
          <div className={classes.row}>
            <div className={classes.titleCell}>
              <div className={classes.titleText}>{data.name}</div>
            </div>
          </div>
          <div className={classes.ratingCell}>
            <Rate disabled allowHalf value={data.accredited ? data.rating : 0} />
            {data.accredited && (
              <div className={classes.reviews}>
                <FontAwesomeIcon className={classes.reviewIcon} icon={faCommentLines}/>
                {data.reviewCount} Reviews
              </div>
            )}
          </div>
          <div className={classes.accreditationCell}>
            {renderAccreditedLogo()}
            {renderNDISLogo()}
          </div>
          <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
            {!externalDirectoryMode && renderDirectConnectButton()}
          </div>
          <div className={classes.mobileDescription}>
            {bodyText(data, classes)}
          </div>
          {(isFullAccessTier && endorsedSpecialisations.length > 0) && (
            <div className={classes.specialisationCell}>
              <SpecialisationPanel specialisations={endorsedSpecialisations}/>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <a
        href={`/provider/${data.slug}`}
        style={{width: '100%'}}
        {...(externalDirectoryMode && {target: '_blank', rel: 'noopener noreferrer'})}
      >
        {useMobileLayout ? mobileLayout() : desktopLayout()}
      </a>
      <DirectConnectModal
        open={directConnectOpen}
        serviceData={data.services}
        onCancel={() => setDirectConnectOpen(false)}
        bgTransparency={0.9}
      />
    </>
  );
};


ProviderCard.propTypes = {
  data: PropTypes.object.isRequired,
  externalDirectoryMode: PropTypes.bool,
};

export default ProviderCard;
