import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { Modal as AntdModal, Select as AntdSelect, Button as AntdButton } from 'antd';
import Recaptcha from 'react-recaptcha';
import Lottie from 'lottie-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandWave } from '@fortawesome/pro-solid-svg-icons';
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as EmailValidator from 'email-validator';

import style from 'style';
import { Textbox } from 'providerSite/components/inputs';
import QualifiedServiceIcon from 'components/QualifiedServiceIcon';
import RecaptchaProvider from 'components/RecaptchaProvider';
import config from 'config';
import { ServiceRequestAPI, useMutateApi } from 'providerSite/api';
import animationAsset from 'assets/call-center.json';
import successAnimationAsset from 'assets/success-tick.json';
import Spinner from 'components/Spinner';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .flex {
    width: 100%;
    display: flex;
    column-gap: 10px;
    margin-top: 10px;
    @media (max-width: ${style.sizes.large}) {
      flex-direction: column;
      align-items: center;
      row-gap: 5px;
    }
  }
  
  .title {
    font-size: 22px;
    font-weight: 500;
  }
  
  .subtitle {
    margin-bottom: 10px;
    font-size: 11px;
    font-weight: 500;
    color: #686868;
    margin-top: -2px;
  }
  
  .dropdown-title {
    width: 100%;
    text-align: start;
    margin-left: 3px;
    font-weight: 500;
    margin-bottom: 5px;
    color: black;
  }
  
  .lottie-container {
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.3));
  }
  
  .button-container {
    width: 100%;
    display: flex;
    column-gap: 20px;
    margin-top: 20px;
    @media (max-width: ${style.sizes.large}) {
      flex-direction: column-reverse;
      align-items: center;
      row-gap: 15px;
    }
  }
`;

const Modal = styled(AntdModal)`
  .ant-modal-content {
    border-radius: 30px;
    background: ${({$bgTransparency}) => `rgba(255, 255, 255, ${$bgTransparency ? $bgTransparency : '0.8'})`};
    backdrop-filter: saturate(180%) blur(10px);
    padding-bottom: 5px;
    margin-top: 70px;
  }
`;

const Select = styled(AntdSelect)`
  margin-bottom: 5px;
  .ant-select-selector {
    min-height: 42px !important;
    border-radius: 12px !important;
    border-color: ${style.colors.secondary} !important;
  }
  .ant-select-selection-item {
    height: auto !important;
    margin-top: 1px !important;
    font-weight: 500 !important;
  }
  .ant-select-selection-placeholder {
    margin-top: 5px !important;
  }
`;

const Button = styled(AntdButton)`
  width: 100%;
  height: 40px;
  border-radius: 14px;
`;

const CompleteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 15px;
  
  .title {
    font-size: 28px;
    font-weight: 500;
  }
  
  .subtitle {
    font-size: 18px;
  }
  
  .lottie-container {
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.4));
  }
`;

const DirectConnectModal = ({ open, serviceData, service, onCancel, bgTransparency }) => {

  const reCaptchaRef = useRef();
  const [data, setData] = useState({});
  const [isRecaptchaLoading, setIsRecaptchaLoading] = useState(true);
  const [isComplete, setIsComplete] = useState(false);

  const providerName = Array.isArray(serviceData) && serviceData.length ? serviceData[0].providerName : '';
  const providerUuid = Array.isArray(serviceData) && serviceData.length ? serviceData[0].providerUuid : null;

  const mutation = useMutateApi(ServiceRequestAPI.CREATE_DIRECT_CONNECT, 'POST', {
    params: {
      providerUuid,
    },
    onSuccess: () => setIsComplete(true),
  });

  const updateData = field => value => setData(
    prevState => ({...prevState, [field]: value}));

  const updateRecaptchaAnswer = code => updateData('_recaptchaCode')(code);

  const textboxStyle = {
    borderRadius: '12px',
    borderColor: style.colors.secondary,
  };

  const titleStyle = {
    color: 'black',
  };

  const compare = ( a, b ) => {
    if ( a.service < b.service ){
      return -1;
    }
    if ( a.service > b.service ){
      return 1;
    }
    return 0;
  };

  const _serviceSet = new Set();
  const eligibleServices = serviceData?.filter(item => {
    if (_serviceSet.has(item.service) || !item.directConnectConfig.eligible) return false;
    _serviceSet.add(item.service);
    return true;
  });

  eligibleServices && eligibleServices.sort(compare);

  const handleSubmit = () => {
    mutation.mutate(data);
  };

  useEffect(() => {
    updateData('service')(service);
  }, [service]);

  const _getKey = index => `Option Row - ${index}`;
  const serviceNames = eligibleServices ? eligibleServices.map((item, idx) => {
    return {value: item.service, label: <Row key={_getKey(idx)} name={item.service} />};
  }) : [];

  const renderComplete = () => (
    <CompleteContainer>
      <div className="lottie-container">
        <Lottie
          initialSegment={[0, 108]}
          animationData={successAnimationAsset}
          style={{width: '250px', marginTop: '-10px', marginBottom: '-5px'}}
        />
      </div>
      <div className="title">
        Thanks {data.name}, It&apos;s done.
      </div>
      <div className="subtitle">
        <span style={{color: style.colors.secondary}}><b>{providerName}</b></span> will get in touch with you shortly!
      </div>
      <Button
        style={{width: '150px', marginTop: '30px'}}
        onClick={() => {
          setIsComplete(false);
          onCancel();
        }}
      >
        Close
      </Button>
    </CompleteContainer>
  );

  const renderForm = () => (
    <RecaptchaProvider>
      <Container>
        <div className="title">
          Connect Directly with <span style={{color: style.colors.secondary}}>{providerName}</span>
        </div>
        <div className="subtitle">
          FAST • EASY • DIRECT
        </div>
        <div className="lottie-container">
          <Lottie
            loop
            animationData={animationAsset}
            style={{width: '250px', marginTop: '-10px', marginBottom: '-5px'}}
          />
        </div>
        <div className="dropdown-title">
          Available Services
        </div>
        <Select
          style={{
            width: '100%',
          }}
          value={data.service ? data.service : service}
          placeholder="Select an available service"
          onSelect={updateData('service')}
          options={serviceNames}
        />
        <div className="flex">
          <Textbox
            style={textboxStyle}
            titleStyle={titleStyle}
            title="Name"
            placeholder="Just first name is fine!"
            onChange={updateData('name')}
            colouredBorder
          />
          <Textbox
            style={textboxStyle}
            titleStyle={titleStyle}
            title="Phone Number or Email"
            validator={value => !!value && (isValidPhoneNumber(value, 'AU') || EmailValidator.validate(value))}
            errorMsg="Please enter a valid phone number or email"
            onChange={updateData('contact')}
            colouredBorder
          />
        </div>
        <Textbox
          style={textboxStyle}
          titleStyle={titleStyle}
          title="Additional Details (Optional)"
          placeholder="Optional..."
          onChange={updateData('details')}
          multiline multilineRows={3}
          colouredBorder
        />
        <Recaptcha
          ref={reCaptchaRef}
          sitekey={config.settings.RECAPTCHA_KEY}
          render="explicit"
          onloadCallback={() => {
            updateRecaptchaAnswer(null);
            setIsRecaptchaLoading(false);
          }}
          verifyCallback={(code) => updateRecaptchaAnswer(code)}
          expiredCallback={() => updateRecaptchaAnswer(null)}
        />
        {isRecaptchaLoading && <Spinner size="80px" />}
        <div className="button-container">
          <Button style={{maxWidth: '120px'}} onClick={onCancel}>Cancel</Button>
          <Button
            type="primary"
            disabled={!data.service || !data.contact || !data.name || !data._recaptchaCode}
            onClick={handleSubmit}
            style={{flexGrow: 3}}
            loading={mutation.isLoading}
          >
            <FontAwesomeIcon style={{marginRight: '7px'}} icon={faHandWave} beatFade />
            Connect
          </Button>
        </div>
      </Container>
    </RecaptchaProvider>
  );

  return (
    <Modal
      visible={open}
      closable={false}
      maskStyle={{background: 'rgba(0, 0, 0, 0.06)'}}
      footer={null}
      onCancel={onCancel}
      destroyOnClose
      $bgTransparency={bgTransparency}
    >
      {isComplete ? renderComplete() : renderForm()}
    </Modal>
  );
};

DirectConnectModal.propTypes = {
  open: PropTypes.bool,
  serviceData: PropTypes.arrayOf(PropTypes.object),
  service: PropTypes.string,
  onCancel: PropTypes.func,
  bgTransparency: PropTypes.string,
};

const RowContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  height: 38px;
  font-weight: 500;

  .icon-container {
    background: #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 28px;
    border-radius: 6px;
  }

  .icon {
    width: 20px;
    color: ${style.colors.palette.black};
  }
`;

const Row = ({ name }) => {
  return (
    <RowContainer>
      <div className="icon-container">
        <QualifiedServiceIcon.Regular serviceName={name} className="icon" />
      </div>
      <div>{name}</div>
    </RowContainer>
  );
};

Row.propTypes = {
  name: PropTypes.string,
};


export default DirectConnectModal;

