import { request, makePath } from './http';
const API_NAME = 'review';

export function getReview(review_uuid, with_comments=true) {
  return request('GET', makePath(API_NAME, review_uuid), {params: {with_comments}});
}

export function getProviderReviews(provider_uuid, with_comments=true, exclude_responded=false, exclude_inactive=true, exclude_listing_reviews=false) {
  return request('GET', makePath(API_NAME, 'provider', provider_uuid), {params: {with_comments, exclude_responded, exclude_inactive, exclude_listing_reviews}});
}

export function getListingReviews(listing_uuid, with_comments=true, exclude_responded=false, exclude_inactive=true) {
  return request('GET', makePath(API_NAME, 'listing', listing_uuid), {params: {with_comments, exclude_responded, exclude_inactive}});
}

export function createReview(provider_uuid, review, phone, email, otp) {
  return request('POST', makePath(API_NAME, 'provider', provider_uuid), {body: {review, phone, email, otp}});
}

export function createComment(review_uuid, providerUuid, data) {
  return request('POST', makePath(API_NAME, review_uuid, 'comment', 'provider', providerUuid), {body: data});
}

export function updateComment(comment_uuid, providerUuid, data) {
  return request('PATCH', makePath(API_NAME, 'comment', comment_uuid, 'provider', providerUuid), {body: data});
}

export function getCarouselItems() {
  return request('GET', makePath(API_NAME, 'carousel'));
}

export function alreadyReviewed(phone, item_type, item_uuid) {
  return request('GET', makePath(API_NAME, 'reviewed'), {params: {phone, item_type, item_uuid}});
}

export function adminReviewList(item_type=null, item_uuid=null, with_comments=false, limit=100, offset=0, status='active') {
  return request('GET', makePath(API_NAME, 'admin'), {params: {item_type, item_uuid, with_comments, limit, offset, status}});
}

export function adminUpdateReview(review_uuid, data) {
  return request('PATCH', makePath(API_NAME, 'admin', 'update', review_uuid), {body: data});
}

export function submitFeedback(data) {
  return request('POST', makePath(API_NAME, 'feedback'), {body: data});
}
