import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faThumbsUp, faHandshake } from '@fortawesome/pro-regular-svg-icons';
import { faPhone, faSparkles, faEnvelope, faEarthOceania } from '@fortawesome/pro-solid-svg-icons';
import { faFacebook, faLinkedin, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

import Card from './Card';
import style from 'style';
import * as track from 'lib/track';
import WriteReview from 'components/dialogues/WriteReview';
import EndorseService from 'components/dialogues/EndorseService';
import DirectConnectModal from 'components/DirectConnectModal';
import {
  SUBSCRIPTION_TIER_ACCREDITATION_PLUS,
  SUBSCRIPTION_TIER_ENTERPRISE,
} from 'lib/constants';
import PhoneModal from './PhoneModal';


const SOCIAL_MAP = {
  facebook: {
    icon: faFacebook,
    color: '#3b5998',
    tooltip: 'Facebook',
  },
  linkedin: {
    icon: faLinkedin,
    color: '#0072b1',
    tooltip: 'LinkedIn',
  },
  instagram: {
    icon: faInstagram,
    color: '#CE125B',
    tooltip: 'Instagram',
  },
  twitter: {
    icon: faTwitter,
    color: '#00acee',
    tooltip: 'Twitter',
  },
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  
  .button {
    height: 48px;
    :hover {
      border-color: ${style.colors.primary};
      background: #121d4d;
    }
  }
  .button-ghost {
    :hover {
      border-color: ${style.colors.secondary};
      background: ${style.colors.secondary};
      color: ${style.colors.palette.white};
    }
  }
`;

const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const SocialsContainer = styled.div`
  margin-top: auto;
  display: flex;
  column-gap: 10px;
  align-items: center;
  justify-content: space-around;
`;

const Contact = ({ data, serviceData, isLoading }) => {
  const location = useLocation();
  const history = useHistory();

  const [writeReviewOpen, setWriteReviewOpen] = useState(location.hash === '#review');
  const [endorseServiceOpen, setEndorseServiceOpen] = useState(location.hash === '#endorsement');
  const [directConnectOpen, setDirectConnectOpen] = useState(false);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false);

  const eventArgs = null;
  const hasServices = !!serviceData && Array.isArray(serviceData) && !!serviceData.length;

  const isFullAccessTier = [
    SUBSCRIPTION_TIER_ACCREDITATION_PLUS,
    SUBSCRIPTION_TIER_ENTERPRISE,
  ].includes(data.subscription);

  const clickWriteReview = () => {
    setEndorseServiceOpen(false);
    track.sendEvent('write provider review button', 'click', eventArgs, [data.uuid]);
    setWriteReviewOpen(true);
    history.push(location.pathname + '#review');
  };

  const clickEndorseService = () => {
    setWriteReviewOpen(false);
    track.sendEvent('endorse provider button', 'click', eventArgs, [data.uuid]);
    setEndorseServiceOpen(true);
    history.push(location.pathname + '#endorsement');
  };

  useEffect(() => {
    if (location.hash === '#review') setWriteReviewOpen(true);
  }, [location.hash]);

  const handleDCButtonClick = () => {
    setDirectConnectOpen(true);
  };

  const handleContactTrackEvent = contactType => () => {
    track.sendEvent(`contact - ${contactType}`, track.EVENT_CLICK, {}, [data.uuid]);
  };

  const handleSocialTrackEvent = socialName => () => {
    track.sendEvent(`social - ${socialName}`, track.EVENT_CLICK, {}, [data.uuid]);
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${data.email}?subject=Participant%20Request%20from%20Clickability&body=Hi%2C%0D%0A%0D%0AI%20would%20like%20to%20request%20more%20information%20after%20seeing%20your%20directory%20listing%20on%20the%20Clickability%20website.`;
  };

  return (
    <Card loadingRows={10} isLoading={isLoading}>
      <Container>
        <ButtonContainer>
          {(data.accredited && hasServices) && (
            <Button className="button" type="primary" block onClick={handleDCButtonClick}>
              <FontAwesomeIcon style={{marginRight: '5px', fontSize: '20px', verticalAlign: '-5px'}} icon={faHandshake}/>
              Connect with this provider
            </Button>
          )}
          <Button className="button-ghost" block onClick={clickWriteReview}>
            <FontAwesomeIcon style={{marginRight: '5px'}} icon={faStar} />
            Write a Review
          </Button>
          <Button className="button-ghost" block onClick={clickEndorseService}>
            <FontAwesomeIcon style={{marginRight: '5px'}} icon={faThumbsUp} />
            Endorse this provider
          </Button>
        </ButtonContainer>
        <ContactContainer>
          {(!!data.phone && isFullAccessTier) && (
            <ContactDisplayer
              icon={faPhone}
              value={data.phone}
              onClick={() => setIsPhoneModalOpen(true)}
              trackEventCb={handleContactTrackEvent('phone')}
            />
          )}
          {!!data.email && (
            <ContactDisplayer
              icon={faEnvelope}
              value={data.email}
              onClick={handleEmailClick}
              trackEventCb={handleContactTrackEvent('email')}
            />
          )}
          {(!!data.website && data.accredited) && (
            <ContactDisplayer
              icon={faEarthOceania}
              value="Visit Website"
              url={data.website}
              onClick={() => {}}
              trackEventCb={handleContactTrackEvent('website')}
            />
          )}
        </ContactContainer>
        {(data.accredited && (!!data.facebookUrl || !!data.instagramUrl || !!data.twitterUrl || !!data.linkedinUrl)) && (
          <SocialsContainer>
            {!!data.facebookUrl && <SocialDisplayer type="facebook" url={data.facebookUrl} trackEventCb={handleSocialTrackEvent('facebook')} />}
            {!!data.instagramUrl && <SocialDisplayer type="instagram" url={data.instagramUrl} trackEventCb={handleSocialTrackEvent('instagram')} />}
            {!!data.twitterUrl && <SocialDisplayer type="twitter" url={data.twitterUrl} trackEventCb={handleSocialTrackEvent('twitter')} />}
            {!!data.linkedinUrl && <SocialDisplayer type="linkedin" url={data.linkedinUrl} trackEventCb={handleSocialTrackEvent('linkedin')} />}
          </SocialsContainer>
        )}
      </Container>
      <WriteReview
        open={writeReviewOpen}
        data={data}
        onClose={()=>{
          setWriteReviewOpen(false);
          history.push(location.pathname);
        }}
        onEndorse={clickEndorseService}
      />
      <EndorseService
        open={endorseServiceOpen}
        data={data}
        onClose={()=>{
          setEndorseServiceOpen(false);
          history.push(location.pathname);
        }}
        onReview={clickWriteReview}
      />
      <PhoneModal
        open={isPhoneModalOpen}
        onCancel={() => setIsPhoneModalOpen(false)}
        data={data}
        onEmailClick={() => {
          handleContactTrackEvent('email')();
          handleEmailClick();
        }}
      />
      <DirectConnectModal
        open={directConnectOpen}
        serviceData={serviceData}
        onCancel={() => setDirectConnectOpen(false)}
      />
    </Card>
  );
};

Contact.propTypes = {
  data: PropTypes.object,
  serviceData: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
};

const CDContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 20px;
  border: 1px solid ${style.colors.lightBorder};
  border-radius: ${style.borderRadius.medium};
  padding: 8px 10px;
  min-height: 45px;
  
  .button {
    border: 1px solid ${style.colors.lightBorder};
    border-radius: 8px;
    font-size: 11px;
    padding: 4px 6px;
    display: flex;
    column-gap: 5px;
    align-items: center;
    margin-left: auto;
    cursor: pointer;
    color: ${style.colors.palette.battleshipGrey};
    font-weight: 500;
    :hover {
      color: ${style.colors.secondary};
      border-color: ${style.colors.secondary};
    }
  }
  
  .value {
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 110px;
    
    :hover {
      color: ${style.colors.secondary};
      border-color: ${style.colors.secondary};
      text-decoration: underline;
    }
  }
`;

const ValueContainer = styled.div`
  font-size: 12px;
  font-weight: 500;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: ${({isShown}) => isShown ? '190px' : '110px'};
  
  :hover {
    cursor: pointer;
    color: ${style.colors.secondary};
    text-decoration: underline;
    
    ${({noOnClick, isShown}) => noOnClick && isShown && css`
      color: unset;
      text-decoration: unset;
      cursor: unset;
    `}
  }
`;

const ContactDisplayer = ({ icon, value, onClick, url, trackEventCb }) => {

  const [isShown, setIsShown] = useState(!!url);

  const onShowClick = () => {
    setIsShown(true);
    trackEventCb && trackEventCb();
  };

  const handleClick = () => {
    !isShown && onShowClick();
    onClick && onClick();
  };

  return (
    <CDContainer>
      <FontAwesomeIcon style={{color: style.colors.secondary}} icon={icon} />
      <ValueContainer
        onClick={handleClick}
        isShown={isShown}
        noOnClick={!onClick}
      >
        {isShown ? (
          !url ? value : (
            <a
              style={{color: 'unset', transition: 'none'}}
              href={getClickableUrl(url)}
              target="_blank"
              rel="noreferrer"
              onClick={trackEventCb}
            >
              {value}
            </a>
          )
        ) : maskString(value, 4)}
      </ValueContainer>
      {!isShown && (
        <div className="button" onClick={handleClick}>
          <FontAwesomeIcon style={{marginBottom: '2px'}} icon={faSparkles}/>
          Show
        </div>
      )}
    </CDContainer>
  );
};

ContactDisplayer.propTypes = {
  icon: PropTypes.object,
  value: PropTypes.string,
  onClick: PropTypes.func,
  url: PropTypes.string,
  trackEventCb: PropTypes.func,
};

const SocialContainer = styled.div`
  cursor: pointer;
  .icon {
    font-size: 22px;
  }
`;

const SocialDisplayer = ({ type, url, trackEventCb }) => {

  const social = SOCIAL_MAP[type];

  return (
    <SocialContainer>
      <Tooltip
        title={social.tooltip}
        overlayInnerStyle={{
          fontSize: '10px',
          fontWeight: 500,
          padding: '5px 8px 2px 8px',
          margin: 0,
          minHeight: 0,
        }}
      >
        <a href={getClickableUrl(url)} target="_blank" rel="noreferrer" onClick={trackEventCb}>
          <FontAwesomeIcon className="icon" color={social.color} icon={social.icon} />
        </a>
      </Tooltip>
    </SocialContainer>
  );
};

SocialDisplayer.propTypes = {
  type: PropTypes.string,
  url: PropTypes.string,
  trackEventCb: PropTypes.func,
};

const maskString = (str, maxChars) => {
  if (str.length <= maxChars) {
    return str;
  }

  const maskedChars = Math.max(maxChars, 0); // Number of characters to be masked
  const remainingChars = str.length - maskedChars;

  return str.substr(0, maskedChars) + '*'.repeat(remainingChars);
};

const getClickableUrl = url => {
  if (!url) return url;
  const _url = url.toLowerCase().trim();
  if (_url.startsWith('https://') || _url.startsWith('http://'))
    return _url;
  return 'https://' + _url;
};



export default Contact;
