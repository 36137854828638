import React, { useEffect } from 'react';

import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import MetaTags from 'components/MetaTags';
import Page from 'components/Page';
import style from 'style';
import config from 'config';
import Header from './components/Header';
import { ProviderAPI, ReviewAPI, useGetApi } from 'providerSite/api';
import Services from './components/Services';
import Overview from './components/Overview';
import Locations from './components/Locations';
import Contact from './components/Contact';
import ServiceLocations from './components/ServiceLocations';
import Reviews from './components/Reviews';
import Specialisations from './components/Specialisations';
import Languages from './components/Languages';
import AccessMethods from './components/AccessMethods';
import AgeGroups from './components/AgeGroups';
import { EDIT_PROVIDER, hasPermission } from 'lib/permissions';
import AdminAccessButton from '../components/AdminAccessButton';
import * as track from 'lib/track';
import * as gtm from 'lib/gtm';
import ReviewSnippets from '../../../components/review/ReviewSnippets';
import Gallery from './components/Gallery';
import {
  SUBSCRIPTION_TIER_ACCREDITATION_PLUS,
  SUBSCRIPTION_TIER_ENTERPRISE
} from 'lib/constants';


const CUSTOM_MOBILE_SIZE = '1165px';

const Main = styled.div`
  padding: 30px 10px 50px 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  font-size: 14px;
`;

const Container = styled.div`
  display: flex;
  width: 100%;
  column-gap: 30px;
  color: black;
  max-width: ${style.sizes.large};
  
  @media (max-width: ${CUSTOM_MOBILE_SIZE}) {
    flex-direction: column;
    row-gap: 30px;
  }
`;

const LeftContainer = styled.div`
  max-width: 75%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  @media (max-width: ${CUSTOM_MOBILE_SIZE}) {
    max-width: 100%;
  }
`;

const RightContainer = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  @media (max-width: ${CUSTOM_MOBILE_SIZE}) {
    width: 100%;
  }
`;

const ProviderPage = () => {
  const { uuid: slug } = useParams();
  const isMobile = useMediaQuery({query: `(max-width: ${CUSTOM_MOBILE_SIZE})`});
  const { isAuthenticated, profile } = useSelector(state => state.auth);
  const history = useHistory();

  const { isLoading: isProviderLoading, data: _providerData } = useGetApi(ProviderAPI.GET_PROVIDER_V2, {
    params: {
      slug: slug
    },
    onError: () => history.push('/not-found')
  });

  const { isLoading: isServiceLoading, data: _serviceData } = useGetApi(ProviderAPI.GET_PROVIDER_SERVICES_WITH_METADATA_V2, {
    params: {
      slug: slug
    },
  });

  const { isLoading: isReviewLoading, data: _reviewData } = useGetApi(ReviewAPI.GET_REVIEW, {
    params: {
      providerUuid: slug
    },
    queryParams: {
      withComments: true,
      excludeResponded: false,
      excludeInactive: true,
      excludeListingReviews: false,
    },
  });

  const { isLoading: isPremiseLoading, data: _premiseData } = useGetApi(ProviderAPI.GET_PROVIDER_PREMISES, {
    params: {
      slug: slug
    },
  });

  const { isLoading: isSpecialisationLoading, data: _specialisationData } = useGetApi(ProviderAPI.GET_PROVIDER_SPECIALISATIONS, {
    params: {
      slug: slug
    },
  });

  const { isLoading: isGalleryImageDataLoading, data: _galleryImageData } = useGetApi(ProviderAPI.GET_PROVIDER_GALLERY_IMAGES, {
    params: {
      uuid: slug
    },
  });

  const providerData = _providerData ? _providerData : {};
  const serviceData = _serviceData ? _serviceData : {};
  const premiseData = _premiseData ? _premiseData : [];
  const specialisationData = _specialisationData ? _specialisationData : [];
  const reviewData = _reviewData ? _reviewData : [];
  const galleryImageData = _galleryImageData ? _galleryImageData : [];

  const hasEditProfilePermission = isAuthenticated && hasPermission(profile, providerData.uuid, EDIT_PROVIDER);

  const renderReviewsComponent = () => (
    <Reviews
      data={reviewData}
      providerName={providerData.name}
      providerUuid={providerData.uuid}
      rating={providerData.rating}
      count={providerData.reviewCount}
      isLoading={isReviewLoading}
    />
  );

  const isFullAccessTier = [
    SUBSCRIPTION_TIER_ACCREDITATION_PLUS,
    SUBSCRIPTION_TIER_ENTERPRISE,
  ].includes(providerData.subscription);

  const renderContactComponent = () => (
    <Contact data={providerData} serviceData={serviceData.services} isLoading={isProviderLoading} />
  );

  const renderAdminAccessComponent = () => (
    hasEditProfilePermission && <AdminAccessButton uuid={providerData.uuid} />
  );

  useEffect(() => {
    gtm.sendEvent('provider', { uuid: providerData.uuid });
    track.sendEvent('provider profile', track.EVENT_VIEW, {
      slug: slug,
    }, [providerData.uuid]);
  }, [providerData.uuid]);

  return (
    <Page title={providerData.name ? `${providerData.name} - Clickability` : 'Clickability'}>
      <MetaTags
        article
        title={providerData.name}
        description={
          `Read ratings and reviews from the people who use ${providerData.name}. 
          Clickability is an Australian disability service directory.`
        }
        image={providerData.imagePath}
        url={`${config.website.URL}/provider/${providerData.slug}`}
      />
      <Main>
        <Container>
          <LeftContainer>
            <Header data={providerData} isLoading={isProviderLoading} />
            {isMobile && renderAdminAccessComponent()}
            {isMobile && renderContactComponent()}
            <Services
              data={serviceData.services}
              isLoading={isServiceLoading}
              disableServiceClick={!providerData.accredited}
            />
            <Overview data={providerData.fullDescription} isLoading={isProviderLoading} />
            {isFullAccessTier && <Gallery data={galleryImageData} isLoading={isGalleryImageDataLoading}/>}
            <Locations data={premiseData} isLoading={isPremiseLoading} />
            {!isMobile && renderReviewsComponent()}
          </LeftContainer>
          <RightContainer>
            {!isMobile && renderAdminAccessComponent()}
            {!isMobile && renderContactComponent()}
            <ServiceLocations data={serviceData.metadata?.locations} isLoading={isServiceLoading} />
            <Languages data={serviceData.metadata?.languages} isLoading={isServiceLoading} />
            <Specialisations data={specialisationData} isLoading={isSpecialisationLoading} />
            <AccessMethods data={serviceData.metadata?.accessMethods} isLoading={isServiceLoading} />
            <AgeGroups data={serviceData.metadata?.ageGroups} isLoading={isServiceLoading} />
            {isMobile && renderReviewsComponent()}
          </RightContainer>
        </Container>
        <ReviewSnippets
          data={providerData}
          reviews={reviewData}
          isLoading={isProviderLoading || isReviewLoading}
        />
      </Main>
    </Page>
  );
};


export default ProviderPage;
